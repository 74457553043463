import defaultSetting from "./config.js";
import { common_extend } from "./utils/util";
import Store from "./store";
import { locales } from "./locale/locale";
import server from "./controllers/server";
import luckysheetConfigsetting from "./controllers/luckysheetConfigsetting";
import sheetmanage from "./controllers/sheetmanage";
import luckysheetsizeauto from "./controllers/resize";
import luckysheetHandler from "./controllers/handler";
import { initialFilterHandler } from "./controllers/filter";
import { initialMatrixOperation } from "./controllers/matrixOperation";
import { initialSheetBar } from "./controllers/sheetBar";
import { formulaBarInitial } from "./controllers/formulaBar";
import { rowColumnOperationInitial } from "./controllers/rowColumnOperation";
import { keyboardInitial } from "./controllers/keyboard";
import { orderByInitial } from "./controllers/orderBy";
import { initPlugins } from "./controllers/expendPlugins";
import {
  getluckysheetfile,
  getluckysheet_select_save,
  getconfig,
  getConditionFormatCells,
} from "./methods/get";
import { setluckysheet_select_save } from "./methods/set";
import { luckysheetrefreshgrid, jfrefreshgrid } from "./global/refresh";
import functionlist from "./function/functionlist";
import { luckysheetlodingHTML } from "./controllers/constant";
import { getcellvalue, getdatabyselection } from "./global/getdata";
import { setcellvalue } from "./global/setdata";
import { selectHightlightShow } from "./controllers/select";
import { zoomInitial } from "./controllers/zoom";
// import { printInitial } from "./controllers/print";
import method from "./global/method";
import * as Sentry from "@sentry/browser";

import * as api from "./global/api";

import flatpickr from "flatpickr";
import Mandarin from "flatpickr/dist/l10n/zh.js";
import { initListener } from "./controllers/listener";
import { hideloading, showloading } from "./global/loading.js";
import { luckysheetextendData } from "./global/extend.js";
import { initChat } from "./demoData/chat.js";

import { FormulaManager } from "./global/formulaManager";

let luckysheet = {};

// mount api
// luckysheet.api = api;
// Object.assign(luckysheet, api);

luckysheet = common_extend(api, luckysheet);

Sentry.init({
  dsn: "https://9e2f67f4ada443458fdac3f5d06724df@fm-sentry.cfoservices.ru/3",
  tracesSampleRate: 0.01,
  environment: "REMOVE_RANGE_VALUES",
  release: "2.1.40",
});

//创建luckysheet表格
luckysheet.create = function (setting) {
  method.destroy();
  // Store original parameters for api: toJson
  Store.toJsonOptions = {};
  for (let c in setting) {
    if (c !== "data") {
      Store.toJsonOptions[c] = setting[c];
    }
  }

  let extendsetting = common_extend(defaultSetting, setting);

  let loadurl = extendsetting.loadUrl,
    menu = extendsetting.menu,
    title = extendsetting.title;

  let container = extendsetting.container;
  Store.container = container;
  Store.luckysheetfile = extendsetting.data;
  Store.defaultcolumnNum = extendsetting.column;
  Store.defaultrowNum = extendsetting.row;
  Store.defaultFontSize = extendsetting.defaultFontSize;
  Store.fullscreenmode = extendsetting.fullscreenmode;
  Store.lang = extendsetting.lang; //language
  Store.allowEdit = extendsetting.allowEdit;
  Store.limitSheetNameLength = extendsetting.limitSheetNameLength;
  Store.defaultSheetNameMaxLength = extendsetting.defaultSheetNameMaxLength;
  Store.fontList = extendsetting.fontList;
  server.gridKey = extendsetting.gridKey;
  server.loadUrl = extendsetting.loadUrl;
  server.updateUrl = extendsetting.updateUrl;
  server.updateImageUrl = extendsetting.updateImageUrl;
  server.title = extendsetting.title;
  server.loadSheetUrl = extendsetting.loadSheetUrl;
  server.allowUpdate = extendsetting.allowUpdate;

  luckysheetConfigsetting.autoFormatw = extendsetting.autoFormatw;
  luckysheetConfigsetting.accuracy = extendsetting.accuracy;
  luckysheetConfigsetting.total = extendsetting.data[0].total;

  luckysheetConfigsetting.loading = extendsetting.loading;
  luckysheetConfigsetting.allowCopy = extendsetting.allowCopy;
  luckysheetConfigsetting.showtoolbar = extendsetting.showtoolbar;
  luckysheetConfigsetting.showtoolbarConfig = extendsetting.showtoolbarConfig;
  luckysheetConfigsetting.showinfobar = extendsetting.showinfobar;
  luckysheetConfigsetting.showsheetbar = extendsetting.showsheetbar;
  luckysheetConfigsetting.showsheetbarConfig = extendsetting.showsheetbarConfig;
  luckysheetConfigsetting.showstatisticBar = extendsetting.showstatisticBar;
  luckysheetConfigsetting.showstatisticBarConfig =
    extendsetting.showstatisticBarConfig;
  luckysheetConfigsetting.sheetFormulaBar = extendsetting.sheetFormulaBar;
  luckysheetConfigsetting.cellRightClickConfig =
    extendsetting.cellRightClickConfig;
  luckysheetConfigsetting.sheetRightClickConfig =
    extendsetting.sheetRightClickConfig;
  luckysheetConfigsetting.pointEdit = extendsetting.pointEdit;
  luckysheetConfigsetting.pointEditUpdate = extendsetting.pointEditUpdate;
  luckysheetConfigsetting.pointEditZoom = extendsetting.pointEditZoom;

  luckysheetConfigsetting.userInfo = extendsetting.userInfo;
  luckysheetConfigsetting.userMenuItem = extendsetting.userMenuItem;
  luckysheetConfigsetting.myFolderUrl = extendsetting.myFolderUrl;
  luckysheetConfigsetting.functionButton = extendsetting.functionButton;

  luckysheetConfigsetting.showConfigWindowResize =
    extendsetting.showConfigWindowResize;
  luckysheetConfigsetting.enableAddRow = extendsetting.enableAddRow;
  luckysheetConfigsetting.enableAddBackTop = extendsetting.enableAddBackTop;
  luckysheetConfigsetting.addRowCount = extendsetting.addRowCount;
  luckysheetConfigsetting.enablePage = extendsetting.enablePage;
  luckysheetConfigsetting.pageInfo = extendsetting.pageInfo;

  luckysheetConfigsetting.editMode = extendsetting.editMode;
  luckysheetConfigsetting.beforeCreateDom = extendsetting.beforeCreateDom;
  luckysheetConfigsetting.workbookCreateBefore =
    extendsetting.workbookCreateBefore;
  luckysheetConfigsetting.workbookCreateAfter =
    extendsetting.workbookCreateAfter;
  luckysheetConfigsetting.remoteFunction = extendsetting.remoteFunction;
  luckysheetConfigsetting.customFunctions = extendsetting.customFunctions;

  luckysheetConfigsetting.fireMousedown = extendsetting.fireMousedown;
  luckysheetConfigsetting.forceCalculation = extendsetting.forceCalculation;
  luckysheetConfigsetting.plugins = extendsetting.plugins;

  luckysheetConfigsetting.rowHeaderWidth = extendsetting.rowHeaderWidth;
  luckysheetConfigsetting.columnHeaderHeight = extendsetting.columnHeaderHeight;

  luckysheetConfigsetting.defaultColWidth = extendsetting.defaultColWidth;
  luckysheetConfigsetting.defaultRowHeight = extendsetting.defaultRowHeight;

  luckysheetConfigsetting.title = extendsetting.title;
  luckysheetConfigsetting.container = extendsetting.container;
  luckysheetConfigsetting.hook = extendsetting.hook;

  luckysheetConfigsetting.pager = extendsetting.pager;

  luckysheetConfigsetting.initShowsheetbarConfig = false;

  luckysheetConfigsetting.imageUpdateMethodConfig =
    extendsetting.imageUpdateMethodConfig;

  if (Store.lang === "zh") flatpickr.localize(Mandarin.zh);
  if (Store.lang === "ru") flatpickr.localize(Mandarin.ru);

  // Store the currently used plugins for monitoring asynchronous loading
  Store.asyncLoad.push(
    ...luckysheetConfigsetting.plugins.map(plugin => plugin.name)
  );

  // Register plugins
  initPlugins(extendsetting.plugins, extendsetting);
  Store.plugins = extendsetting.plugins;

  // Store formula information, including internationalization
  functionlist(extendsetting.customFunctions);

  let devicePixelRatio = extendsetting.devicePixelRatio;
  if (devicePixelRatio == null) {
    devicePixelRatio = 1;
  }
  Store.devicePixelRatio = Math.ceil(devicePixelRatio);

  //loading
  const loadingObj = luckysheetlodingHTML("#" + container);
  Store.loadingObj = loadingObj;

  if (loadurl == "") {
    sheetmanage.initialjfFile(menu, title);
    // luckysheetsizeauto();
    initialWorkBook();
  } else {
    $.post(loadurl, { gridKey: server.gridKey }, function (d) {
      let data = new Function("return " + d)();
      Store.luckysheetfile = data;

      sheetmanage.initialjfFile(menu, title);
      // luckysheetsizeauto();
      initialWorkBook();

      // When data needs to be updated to the background,
      // a WebSocket connection is established
      if (server.allowUpdate) {
        server.openWebSocket();
      }
    });
  }

  initChat();
};

function initialWorkBook() {
  luckysheetHandler(); //Overall dom initialization
  initialFilterHandler(); //Filter initialization
  initialMatrixOperation(); //Right click matrix initialization
  initialSheetBar(); //bottom sheet bar initialization
  formulaBarInitial(); //top formula bar initialization
  rowColumnOperationInitial(); //row and coloumn operate initialization
  keyboardInitial(); //Keyboard operate initialization
  orderByInitial(); //menu bar orderby function initialization
  zoomInitial(); //zoom method initialization
  // printInitial(); //print initialization
  initListener();
}

luckysheet.FormulaManager = FormulaManager;

// Get all table data
luckysheet.getluckysheetfile = getluckysheetfile;

// Get the current form selection
luckysheet.getluckysheet_select_save = getluckysheet_select_save;

// Set current form selection
luckysheet.setluckysheet_select_save = setluckysheet_select_save;

// Get the current table config configuration
luckysheet.getconfig = getconfig;

// The two-dimensional array data is converted into a one-dimensional array in {r, c, v} format
// (the input parameter is two-dimensional data data)
luckysheet.getGridData = sheetmanage.getGridData;

// Generate the two-dimensional array required for the table
// (the incoming parameter is the table data object file)
luckysheet.buildGridData = sheetmanage.buildGridData;

// Refresh the canvas display data according to scrollHeight and scrollWidth
luckysheet.luckysheetrefreshgrid = luckysheetrefreshgrid;

// Refresh canvas
luckysheet.jfrefreshgrid = jfrefreshgrid;

// Get the value of the cell
luckysheet.getcellvalue = getcellvalue;

// Set cell value
luckysheet.setcellvalue = setcellvalue;

// Get selection range value
luckysheet.getdatabyselection = getdatabyselection;

luckysheet.sheetmanage = sheetmanage;
luckysheet.Store = Store;

// Data of the current table
luckysheet.flowdata = function () {
  return Store.flowdata;
};

// Set selection highlight
luckysheet.selectHightlightShow = selectHightlightShow;

// Reset parameters after destroying the table
luckysheet.destroy = method.destroy;

luckysheet.showLoadingProgress = showloading;
luckysheet.hideLoadingProgress = hideloading;
luckysheet.luckysheetextendData = luckysheetextendData;

luckysheet.locales = locales;

// 获取条件格式渲染的单元格数量
luckysheet.getConditionFormatCells = getConditionFormatCells;

export { luckysheet };
