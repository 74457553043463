import HyperFormula, { ExportedChange } from "hyperformula";
import sheetmanage from "../../controllers/sheetmanage";
import server from "../../controllers/server";
import { Logger } from "./logger";
import { CONFIG } from "./config";
import Store from "../../store";
import {
  containsRussian,
  hasCrossRef,
  removeQuotesFromWords,
  removeSingleQuotes,
  wrapRussianSheetsWithQuotes,
} from "./utils";

interface LuckysheetWindow extends Window {
  luckysheet: {
    sheetmanage: typeof sheetmanage;
    formulaManagerDevMode: boolean;
  };
}

type LSCell = {
  m?: string;
  v?: string;
  ct?: { fa: string; t: string };
  f?: string;
};

declare const window: LuckysheetWindow;

export class FormulaManager {
  static instance: FormulaManager;
  manager: HyperFormula;

  constructor() {
    if (FormulaManager.instance) {
      return FormulaManager.instance;
    }

    FormulaManager.instance = this;
  }

  buildAllSheets(sheets: any[]) {
    const russianSheets = sheets
      .filter(sheet => containsRussian(sheet.name))
      .map(sh => sh.name);
    const preparedData = sheets.reduce((acc, currentSheet) => {
      const { name, celldata } = currentSheet;
      // const sheetName = transliterateIfNeeded(name);

      return {
        ...acc,
        [name]: this.convertCellDataToManagerData(celldata, russianSheets),
      };
    }, {});
    this.manager = HyperFormula.buildFromSheets(preparedData, CONFIG);
    // this.manager.on("valuesUpdated", changedCells => {
    //   console.log(changedCells, "changedCellschangedCells");
    //   changedCells.forEach(cell => {
    //     // @ts-ignore
    //     console.log(this.manager.getCellDependents(cell.address));
    //   });
    // });
  }

  getCellValue(row: number, col: number, sheetLSIndex?: string) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);
    // const sheetName = transliterateIfNeeded(sheetNameRaw);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const cellAddress = { col, row, sheet: managerSheetId };
      const val = this.manager.getCellValue(cellAddress);
      return val;
    }
  }

  pasteContent(
    topLeft: { row: number; col: number },
    contentData: string | number[][],
    sheetLSIndex: string
  ) {
    const { row, col } = topLeft;
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const pasteAddress = { row, col, sheet: managerSheetId };
      this.manager.setCellContents(pasteAddress, contentData);
    }
  }

  updateCell(row: number, col: number, cellLS: LSCell, sheetLSIndex?: string) {
    const { v: cellValue, f: formulaRaw } = cellLS || {};
    const russianSheets = this.manager.getSheetNames().filter(containsRussian);

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      formula = wrapRussianSheetsWithQuotes(formula, russianSheets);
    }

    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const value = formula || cellValue;
      const cellAddress = { col, row, sheet: managerSheetId };
      this.manager.setCellContents(cellAddress, value);

      const hasFormula = this.manager.doesCellHaveFormula(cellAddress);
      const formulaCell = this.manager.getCellFormula(cellAddress);

      Logger.info(
        `[${row}, ${col}]: ${hasFormula ? formulaCell : ""} ${
          cellValue ? cellValue : ""
        }`,
        `UPDATE CELL IN ${sheetNameRaw}`
      );
    }
  }

  changeSheet(celldata: LSCell[], isLoad?: boolean, sheetName?: string) {
    if (isLoad) return;

    this.manager.addSheet(`${sheetName}`);
  }

  updateSheetData(data: string[][], sheetId: number) {
    this.manager.setSheetContent(sheetId, data);
  }

  renameSheet(oldSheetName: string, newSheetName: string) {
    const sheetId = this.manager.getSheetId(oldSheetName);

    if (sheetId !== undefined) {
      this.manager.renameSheet(sheetId, `${newSheetName}`);
      this.viewRawSheet(sheetId, newSheetName, `RENAME ${oldSheetName} ->`);
      this.updateDependentFormulas(sheetId);
    }
  }

  addColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.addColumns(managerSheetId, [indexCol, countColumns]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  addRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.addRows(managerSheetId, [indexRow, countRows]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  removeColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.removeColumns(managerSheetId, [indexCol, countColumns]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  removeRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.removeRows(managerSheetId, [indexRow, countRows]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  static viewAllRawSheets() {
    const allRawSheets = this.instance.manager.getAllSheetsSerialized();
    Object.entries(allRawSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA RAW VALUES", listName);
    });
  }

  static viewAllSheetsValues() {
    const allValuesSheets = this.instance.manager.getAllSheetsValues();
    Object.entries(allValuesSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA VALUES", listName);
    });
  }

  private viewRawSheet(sheetId: number, sheetNameRaw: string, action: string) {
    Logger.table(
      this.manager.getSheetSerialized(sheetId),
      action,
      sheetNameRaw
    );
  }

  private getSheetLS(sheetIndex?: string) {
    return window.luckysheet.sheetmanage.getSheetName(sheetIndex);
  }

  private convertCellDataToManagerData(celldata, russianSheets: string[]) {
    let newValuesArrs: string[][] = [[]];

    celldata?.forEach((cell: any) => {
      const { r: row, c: column, v } = cell || {};
      const { v: originalValue, f: formulaRaw } = v || {};

      let formula = formulaRaw;
      if (formula && hasCrossRef(formula)) {
        formula = wrapRussianSheetsWithQuotes(formula, russianSheets);
      }

      if (!newValuesArrs[row]) {
        newValuesArrs[row] = [];
      }
      newValuesArrs[row][column] = formula
        ? formula
        : originalValue?.toString() || "";
    });

    const rowsLength = newValuesArrs.length;

    for (let ii = 0; ii < rowsLength; ii++) {
      if (!newValuesArrs[ii]) {
        newValuesArrs[ii] = new Array(rowsLength).fill(null);
      }
    }

    return newValuesArrs;
  }

  /**
   * Обновляет все формулы, которые зависят от текущего листа
   * Также обновляет состояние LS
   * @param sheetId - айди листа, от которого зависят другие листы
   */
  private updateDependentFormulas(sheetId: number) {
    const cellsForUpdating: any = [];

    const renamedSheet = this.manager.getSheetName(sheetId);
    const allSheets = this.manager
      .getSheetNames()
      .filter(sheetName => sheetName !== renamedSheet);

    const russianSheets = this.manager.getSheetNames().filter(containsRussian);

    allSheets.forEach(sheetName => {
      const currentId = this.manager.getSheetId(sheetName);

      if (currentId !== undefined && renamedSheet) {
        const sheetFormulas = this.manager.getSheetFormulas(currentId);
        const currentFile = Store.luckysheetfile.find(
          shs => shs.name === sheetName
        );

        const currentLSData = currentFile.data;
        const currentSheetIndex = currentFile.index;

        sheetFormulas.forEach((rowItem, row) => {
          rowItem.forEach((updatedFormula, col) => {
            if (
              currentLSData &&
              updatedFormula &&
              updatedFormula.includes(renamedSheet)
            ) {
              currentLSData[row][col] = {
                ...currentLSData[row][col],
                f: removeQuotesFromWords(updatedFormula, russianSheets),
              };

              cellsForUpdating.push({
                ...currentLSData[row][col],
                f: removeQuotesFromWords(updatedFormula, russianSheets),
                address: {
                  r: row,
                  c: col,
                  i: currentSheetIndex,
                },
              });
            }
          });
        });
      }
    });

    server.saveParam("gv", 0, cellsForUpdating);
  }
}
