export class Logger {
  private static readonly LOGGING_KEY = "loggingEnabled";

  private static isEnabled: boolean = Logger.getLoggingState();

  private static getLoggingState(): boolean {
    const savedState = localStorage.getItem(Logger.LOGGING_KEY);
    if (savedState === null) {
      localStorage.setItem(Logger.LOGGING_KEY, "false");
      return false;
    }

    return savedState === "true";
  }

  private static formatMessage(level: string, label: string): string {
    const timestamp = new Date().toISOString();
    return `[${timestamp}] [${level}] [${label}]`;
  }

  public static info(message: any, label: string = "General"): void {
    if (!Logger.isEnabled) return;
    console.info(Logger.formatMessage("INFO", label), message);
  }

  public static warning(message: any, label: string = "General"): void {
    if (!Logger.isEnabled) return;
    console.warn(Logger.formatMessage("WARNING", label), message);
  }

  public static error(message: any, label: string = "General"): void {
    if (!Logger.isEnabled) return;
    console.error(Logger.formatMessage("ERROR", label), message);
  }

  public static debug(message: any, label: string = "General"): void {
    if (!Logger.isEnabled) return;
    console.debug(Logger.formatMessage("DEBUG", label), message);
  }
  public static table(data: any[][], action: string, sheetName: string): void {
    if (!Logger.isEnabled) return;
    console.groupCollapsed(`${action} ${sheetName}`);
    printExcelLikeTable(data);
    console.groupEnd();
  }
}

function printExcelLikeTable(matrix: any[][]): void {
  try {
    const table: { [key: string]: any }[] = [];

    // Количество строк и столбцов в массиве
    const rows = matrix?.length;

    let maxColumns = matrix[0].length;
    for (let r = 0; r < rows; r++) {
      if (matrix[r].length > maxColumns) {
        maxColumns = matrix[r].length;
      }
    }

    // Генерация таблицы с буквами как заголовками колонок
    for (let row = 0; row < rows; row++) {
      const rowData: { [key: string]: any } = { "#": row + 1 }; // Нумерация строк

      for (let col = 0; col < maxColumns; col++) {
        const columnLetter = String.fromCharCode(65 + col); // A, B, C...
        rowData[columnLetter] = matrix[row][col]; // Значение из двумерного массива
      }

      table.push(rowData);
    }

    console.table(table);
  } catch (error) {
    Logger.error("Ошибка в создании матрицы");
  }
}
